exports.components = {
  "component---src-pc-pages-404-tsx": () => import("./../../../src/PCPages/404.tsx" /* webpackChunkName: "component---src-pc-pages-404-tsx" */),
  "component---src-pc-pages-download-tsx": () => import("./../../../src/PCPages/download.tsx" /* webpackChunkName: "component---src-pc-pages-download-tsx" */),
  "component---src-pc-pages-help-tsx": () => import("./../../../src/PCPages/help.tsx" /* webpackChunkName: "component---src-pc-pages-help-tsx" */),
  "component---src-pc-pages-index-tsx": () => import("./../../../src/PCPages/index.tsx" /* webpackChunkName: "component---src-pc-pages-index-tsx" */),
  "component---src-pc-pages-privacy-tsx": () => import("./../../../src/PCPages/privacy.tsx" /* webpackChunkName: "component---src-pc-pages-privacy-tsx" */),
  "component---src-pc-pages-submit-tsx": () => import("./../../../src/PCPages/submit.tsx" /* webpackChunkName: "component---src-pc-pages-submit-tsx" */),
  "component---src-pc-pages-terms-of-service-tsx": () => import("./../../../src/PCPages/terms-of-service.tsx" /* webpackChunkName: "component---src-pc-pages-terms-of-service-tsx" */)
}

